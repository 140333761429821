import React from "react"
import Anchor from "@components/elements/anchor"
import { Accordion } from "@components/common/accordion"
import Div from "@components/elements/div"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import PropTypes from "prop-types"

export const PowerMetricsFAQ = ({ handleClick, microsite }) => {
  return (
    <Div textContainer margin="8rem auto" marginSm="4rem auto">
      <Heading center as="h3" margin="0 0 2rem">
        Frequently Asked Questions
      </Heading>
      <Accordion title={"What is PowerMetrics?"}>
        <Paragraph fontSize="1.1rem">
          PowerMetrics is a self-serve metrics platform and complementary
          analytics solution. It enables data teams to manage organizational
          data while providing controlled access to business users with a
          metrics catalog and streamlined metric-centric analytics.
        </Paragraph>
        <Paragraph fontSize="1.1rem">
          As a hybrid metrics solution, PowerMetrics has the ability to query
          data directly from your data warehouse, import metrics from your
          semantic layer and create native metrics from common file formats and
          hundreds of data services. Explore our&nbsp;
          <Anchor
            link={microsite ? "/integrations" : "/powermetrics/integrations"}
          >
            hybrid integrations approach
          </Anchor>
          .
        </Paragraph>
      </Accordion>
      <Accordion title={"What is a metric catalog?"}>
        <Paragraph fontSize="1.1rem">
          A metric catalog lists and organizes a company’s metrics in a
          centralized location. Curated, certified metrics, managed by the data
          team, give business users fast access to key data points, improve data
          consistency, reduce data duplication, and ensure all metrics are
          meaningful. Governance is assured by granting access rights and
          permissions at the user level to determine who can view or edit each
          metric.
        </Paragraph>
      </Accordion>
      <Accordion
        title={"What are the benefits of metric-centric decision making?"}
      >
        <Paragraph fontSize="1.1rem">
          A well-defined metrics framework enables faster, more reliable
          decision making. The consistent structure of metrics allows for
          effective data monitoring over time and makes it easier to quickly see
          when there are changes in the data.
        </Paragraph>
        <Paragraph fontSize="1.1rem">
          Key members of the organization, through careful consideration, decide
          what each metric measures and how the data is processed. As a result,
          metrics represent deeply meaningful, well-understood information,
          based on agreed-upon standards and definitions.
          {!microsite && (
            <>
              &nbsp;
              <Anchor link="/blog/metrics-key-confident-decision-making">
                Learn more
              </Anchor>
              .
            </>
          )}
        </Paragraph>
      </Accordion>
      <Accordion
        title={"Is PowerMetrics built for data teams or business users?"}
      >
        <Paragraph fontSize="1.1rem">
          The simplest answer to this question is both! PowerMetrics enables
          everyone in the organization to work with centralized, trusted
          metrics, curated by the data team.
        </Paragraph>
        <Paragraph fontSize="1.1rem">
          Data teams use PowerMetrics as an integration tool within their data
          stack. They import metrics from the semantic layer, query data from
          the data warehouse or build native metrics from common file formats
          and data services. All metrics get organized in a centralized catalog
          of metrics, with managed access for business users.
        </Paragraph>
        <Paragraph fontSize="1.1rem">
          Business teams use PowerMetrics as a self-serve analytics tool by
          accessing metrics in the catalog, visualizing and exploring data,
          setting goals, and building their own dashboards and reports.
        </Paragraph>
      </Accordion>
      <Accordion title={"Does PowerMetrics provide a public API?"}>
        <Paragraph fontSize="1.1rem">
          Please <Anchor onClick={handleClick}>contact us</Anchor> for more
          details and your use case for the API. There are several ways you can
          interact programmatically with PowerMetrics. Most of our customers are
          interested in an asset management (CRUD) API related to metrics. This
          can also be used for sandbox environments.
        </Paragraph>
      </Accordion>
      <Accordion title={"Where does PowerMetrics store data?"}>
        <Paragraph fontSize="1.1rem">
          PowerMetrics uses Amazon Web Services (AWS) in Canada to store and
          manage customer information and processed data and AWS in Ireland to
          back up this data. Read our&nbsp;
          <Anchor
            target="_blank"
            rel="noreferrer"
            link="https://www.klipfolio.com/legal/privacy"
          >
            Privacy Statement
          </Anchor>
          &nbsp;for more details.
        </Paragraph>
      </Accordion>
      <Accordion
        title={"How does PowerMetrics protect privacy and ensure security?"}
      >
        <Paragraph fontSize="1.1rem">
          We care deeply about the availability, confidentiality, and integrity
          of your data. Your continued trust in us is our top priority. Read
          more about&nbsp;
          <Anchor
            target="_blank"
            rel="noreferrer"
            link="https://www.klipfolio.com/legal/security"
          >
            how we protect
          </Anchor>
          &nbsp;and ensure&nbsp;
          <Anchor
            target="_blank"
            rel="noreferrer"
            link="https://www.klipfolio.com/legal/privacy"
          >
            privacy
          </Anchor>
          &nbsp;of your data.
        </Paragraph>
      </Accordion>
      <Accordion
        title={"Does PowerMetrics offer documentation resources and support?"}
      >
        <Paragraph fontSize="1.1rem">
          Yes. You&apos;ll find lots of great information in our extensive&nbsp;
          <Anchor
            target="_blank"
            rel="noreferrer"
            link="https://support.klipfolio.com/hc/en-us/categories/360000361994-PowerMetrics"
          >
            Documentation and Support Centre
          </Anchor>
          . Our in-app chatbot is also available 24/7 to answer your questions.
          If you need more help, you can count on our top-rated Technical
          Support Team, who are happy to provide personalized email support. For
          additional resources, check out our&nbsp;
          <Anchor
            target="_blank"
            rel="noreferrer"
            link="https://www.youtube.com/@klipfolio/featured"
          >
            YouTube channel
          </Anchor>
          &nbsp;and&nbsp;
          <Anchor
            target="_blank"
            rel="noreferrer"
            link="https://www.metrichq.org/"
          >
            MetricHQ
          </Anchor>
          , the first community-driven dictionary for metrics and KPIs.
        </Paragraph>
      </Accordion>
      <Accordion title={"Is PowerMetrics a Klipfolio product?"}>
        <Paragraph fontSize="1.1rem">
          Yes. PowerMetrics is one of two products built and owned by&nbsp;
          <Anchor
            target="_blank"
            rel="noreferrer"
            link="https://www.klipfolio.com/"
          >
            Klipfolio
          </Anchor>
          . PowerMetrics is a complementary analytics solution with a
          metric-centric architecture that&apos;s designed for data teams and
          business users in larger organizations.&nbsp;
          <Anchor target="_blank" rel="noreferrer" link="/klips">
            Klipfolio Klips
          </Anchor>
          &nbsp;is an award-winning dashboarding solution for small and
          medium-sized businesses.
        </Paragraph>
      </Accordion>
      <Accordion title={"How does PowerMetrics integrate with my data stack?"}>
        <Paragraph fontSize="1.1rem">
          PowerMetrics works with your existing data stack, ensuring data
          governance and security, while delivering metric-centric, self-serve
          analytics for business teams.
        </Paragraph>
        <Paragraph fontSize="1.1rem">
          As a&nbsp;
          <Anchor
            link={microsite ? "/integrations" : "/powermetrics/integrations"}
          >
            hybrid metrics platform
          </Anchor>
          , with multiple methods of connecting metrics to data in the cloud or
          your infrastructure, PowerMetrics can also grow with you as your data
          stack evolves.
        </Paragraph>
      </Accordion>
      <Accordion title={"Does PowerMetrics have a partner program?"}>
        <Paragraph fontSize="1.1rem">
          Yes. We collaborate closely with our partners, who, just like us, are
          on a mission to bring data analytics to everyone. Selected partners
          can also buy a partner license to help them grow their client
          base.&nbsp;
          <Anchor onClick={handleClick}>Contact us</Anchor>
          &nbsp;to learn more.
        </Paragraph>
      </Accordion>
      <Accordion title={"Can I create metrics from different sources?"}>
        <Paragraph fontSize="1.1rem">
          Yes. You can create metrics from a wide range of data sources. No
          matter how they&apos;re created, all metric types can be combined and
          used interchangeably, in calculations, analysis, and visualizations.
          Learn more about
          <Anchor
            link={microsite ? "/integrations" : "/powermetrics/integrations"}
          >
            PowerMetrics integrations
          </Anchor>
          .
        </Paragraph>
      </Accordion>
    </Div>
  )
}

PowerMetricsFAQ.propTypes = {
  handleClick: PropTypes.func,
  microsite: PropTypes.bool,
}
