import React from "react"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Image from "@components/image"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"
import PropTypes from "prop-types"
import { TestimonialUser } from "@components/common/testimonial"

export const PowerMetricsSocialProof = ({ color = "white" }) => {
  const { imageData } = useStaticQuery(graphql`
    query PowerMetricsSocialProof {
      imageData: allDirectusFiles(
        filter: {
          directus_id: {
            in: [
              "88c788ca-f113-4653-b3f0-607a44c273bb"
              "1192b40b-c5b0-4768-b1af-a5d5c1555cc0"
            ]
          }
        }
      ) {
        edges {
          node {
            directus_id
            cdn
            id
            placeholder
            title
          }
        }
      }
    }
  `)

  const images = processImages(imageData.edges)

  return (
    <>
      <Paragraph
        fontWeight={600}
        fontSize="1.3rem"
        lineHeight={1.5}
        maxWidth="680px"
        margin="-2rem auto 2rem"
        center
        color={color}
        style={{ position: "relative", zIndex: 12 }}
      >
        Recognized as <b>most recommended</b> (Software Advice) and a&nbsp;
        <b>category leader</b> (G2 Crowd) in Analytics and Business
        Intelligence.
      </Paragraph>

      <Image
        style={{ margin: "0 auto", padding: "0 2rem", maxWidth: "500px" }}
        file={images["1192b40b-c5b0-4768-b1af-a5d5c1555cc0"]}
      />

      <Paragraph
        fontSize="1.1rem"
        lineHeight={1.5}
        maxWidth="820px"
        margin="4rem auto 1rem"
        center
        fontWeight={600}
        color={color}
      >
        &quot;PowerMetrics gives non-technical business users exactly what they
        need, without having to worry about the technology underpinnings. A
        centralized, managed inventory of metrics is an excellent complement to
        traditional BI tools, making them more powerful and consistent.&quot;
      </Paragraph>

      <Flex gap="1rem" flexFlow="row" margin="0 auto">
        <TestimonialUser
          color={color}
          image={images["88c788ca-f113-4653-b3f0-607a44c273bb"]}
          name="Adam Roderick"
          title="CEO, Datateer"
        />
      </Flex>
    </>
  )
}

PowerMetricsSocialProof.propTypes = {
  color: PropTypes.string,
}
