import React, { useRef } from "react"
import { motion, useInView } from "framer-motion"
import { useState } from "react"
import styled from "styled-components"
import media from "../../../styles/media"
import Image from "@components/image"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import { GlassyDiv } from "@components/elements/special"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"

const AnimatedHeroWrapper = styled(motion.div)`
  grid-area: stack;
`

const HeroWrapper = ({ active, shadow, image }) => (
  <AnimatedHeroWrapper
    variants={{
      active: {
        opacity: 1,
      },
      inactive: {
        opacity: 0,
      },
    }}
    style={{ display: active ? null : "none" }}
    animate={active ? "active" : "inactive"}
  >
    <Image
      borderStyle={{
        boxShadow: shadow,
      }}
      radius={1}
      file={image}
    />
  </AnimatedHeroWrapper>
)

HeroWrapper.propTypes = {
  active: PropTypes.bool.isRequired,
  shadow: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

const MainImageArea = styled(motion.div)`
  display: grid;
  grid-template-areas: stack;
  position: relative;
  height: max-content;
  padding: 0 2rem;
`

const StackedImageBox = styled(motion.div)`
  z-index: -1;
  grid-area: stack;
  border-radius: 1.5rem;
  border: ${({ noBorder, $borderOpacity }) =>
    noBorder
      ? null
      : `1px solid rgba(255, 255, 255, ${$borderOpacity ?? "0.1"})`};

  background-blend-mode: luminosity;
  backdrop-filter: blur(25px);
  position: absolute;
  inset: -1rem;
  opacity: 0;

  // TODO: Make inset smaller on mobile, will require other fixes though for anim
  ${media.sm`
    border-radius: 0.8rem;
  `}
`

const StackedImage = styled(motion.div)`
  grid-area: stack;
`

const StackedMainImage = styled(StackedImage)`
  position: relative;
  grid-area: stack;
  box-shadow: 0px 8px 14px 0px rgba(33, 38, 79, 0.6);
  max-width: 838px;
  max-height: 433px;
  aspect-ratio: 838/433;
  width: 100%;
  border-radius: 0.75rem;
  overflow: hidden;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.sm`
    border-radius: 0.4rem;
  `}
`

const StackedMainImageOverlay = styled(StackedImage)`
  position: absolute;
  left: 9%;
  right: 9%;
  top: 32%;
  z-index: 10;

  div {
    border-radius: 1rem;
    box-shadow: ${({ $dark }) =>
      $dark ? null : "0px 33px 53px rgba(33, 38, 79, 0.2)"};

    div {
      border-radius: 0.5rem;
      overflow: hidden;
    }
  }

  ${media.sm`
    div {
      border-radius: 0.5rem;
      div {
        border-radius: 0.25rem;
      }
    }
  `}
`

const StackedMainImageDetails = styled(motion.div)`
  position: absolute;
  top: 28%;
  z-index: 10;
  max-width: 1400px;
  width: 100%;

  @media (max-width: 1400px) {
    display: none;
  }
`

const StackedMainImageAI = styled(motion.div)`
  position: absolute;
  z-index: 11;
  width: 100%;
  max-width: 840px;
  bottom: 1rem;
  padding: 0 1rem;
`

const FadeOverlay = styled(motion.div)`
  position: absolute;
  bottom: -6rem;
  height: 332px;
  z-index: 12;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(15, 17, 36, 0) 55%,
    rgba(15, 17, 36, 1) 74%
  );

  ${media.sm`
    height: 264px;
    bottom: -5rem;
  `}
`

const getTransitionConfig = delay => ({
  duration: 0.7,
  delay,
  ease: "easeIn",
})

const useHeroAnimationData = () => {
  const { hero, imageData } = useStaticQuery(graphql`
    query PowerMetricsHeroAnimation {
      hero: directusFiles(
        directus_id: { eq: "05b12453-1856-4a69-a687-9b40f301e66d" }
      ) {
        cdn
        directus_id
        id
        placeholder
        title
      }
      imageData: allDirectusFiles(
        filter: {
          directus_id: {
            in: [
              "243a7d7b-ba0a-484f-b415-86c5b4b93f70"
              "b6dd436d-9cb3-45c4-8075-b641413c65a1"
              "57909c0b-c8f7-41a2-8d1c-7319b65261e8"
            ]
          }
        }
      ) {
        edges {
          node {
            directus_id
            cdn
            id
            placeholder
            title
          }
        }
      }
    }
  `)

  const images = processImages(imageData.edges)
  return { hero, images }
}

export const PowerMetricsHeroAnimation = () => {
  const { hero, images } = useHeroAnimationData()
  const [heroLoaded, setHeroLoaded] = useState(false)
  const [detailsReady, setDetailsReady] = useState(false)

  const mainRef = useRef(null)
  const inView = useInView(mainRef, { once: true, amount: 0.6 })

  return (
    <Flex
      alignItems="center"
      position="relative"
      width="100%"
      margin="7rem 0 8.5rem"
      marginSm="7rem 0"
      ref={mainRef}
    >
      <MainImageArea>
        <StackedImageBox
          $borderOpacity="0.25"
          initial={{
            y: "-4rem",
            scale: 0.8,
          }}
          animate={{
            y: "-3rem",
            scale: 1,
            scaleX: 0.89,
            opacity: 1,
            background: "rgba(218, 220, 242, 0.3)",
          }}
          transition={getTransitionConfig(0.2)}
        />
        <StackedImageBox
          $borderOpacity="0.35"
          initial={{
            y: "-3rem",
            scale: 0.8,
          }}
          animate={{
            y: "-2rem",
            scale: 1,
            scaleX: 0.92,
            background: "rgba(218, 220, 242, 0.4)",
            opacity: 1,
          }}
          transition={getTransitionConfig(0.25)}
        />
        <StackedImageBox
          $borderOpacity="0.45"
          initial={{
            y: "-2rem",
            scale: 0.8,
          }}
          animate={{
            y: "-1rem",
            scale: 1,
            scaleX: 0.96,
            background: "rgba(218, 220, 242, 0.6)",
            opacity: 1,
          }}
          transition={getTransitionConfig(0.3)}
        />
        <StackedImageBox
          $borderOpacity="0.6"
          initial={{
            y: "-1rem",
            scale: 0.8,
          }}
          animate={{
            y: 0,
            scale: 1,
            opacity: 1,
            background: "rgb(218, 220, 242)",
          }}
          transition={getTransitionConfig(0.35)}
        />
        <StackedMainImage
          initial={{
            opacity: 0,
            scale: 0.8,
          }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          style={{ boxShadow: "rgb(0 0 0 / 60%) 0px 0px 64px 0px" }}
          transition={getTransitionConfig(0.4)}
          $loaded={heroLoaded}
        >
          <Image
            style={{ maxWidth: "1300px" }}
            lazy
            file={hero}
            onLoad={() => setHeroLoaded(true)}
          />
        </StackedMainImage>
        <StackedMainImageOverlay
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            delay: 1,
            duration: 0.4,
            ease: "backIn",
          }}
          onAnimationComplete={() => setDetailsReady(true)}
        >
          <GlassyDiv noRadius>
            <Flex margin="0.625rem">
              <Image
                lazy
                aspectRatio={"680/378"}
                file={images["243a7d7b-ba0a-484f-b415-86c5b4b93f70"]}
              />
            </Flex>
          </GlassyDiv>
        </StackedMainImageOverlay>
      </MainImageArea>
      <StackedMainImageAI
        initial={{
          opacity: 0,
          y: "4rem",
        }}
        animate={
          detailsReady &&
          inView && {
            opacity: 1,
            y: 0,
          }
        }
        transition={{
          duration: 0.6,
          ease: "easeInOut",
        }}
      >
        <Image lazy file={images["57909c0b-c8f7-41a2-8d1c-7319b65261e8"]} />
      </StackedMainImageAI>
      <StackedMainImageDetails
        initial={{
          opacity: 0,
        }}
        animate={
          detailsReady &&
          inView && {
            opacity: 1,
          }
        }
        transition={{
          delay: 0.4,
          duration: 0.4,
          ease: "backIn",
        }}
      >
        <Image lazy file={images["b6dd436d-9cb3-45c4-8075-b641413c65a1"]} />
      </StackedMainImageDetails>
    </Flex>
  )
}

export const PowerMetricsHeroAnimationDark = () => {
  const { hero, images } = useHeroAnimationData()
  const [heroLoaded, setHeroLoaded] = useState(false)
  const [detailsReady, setDetailsReady] = useState(false)

  const mainRef = useRef(null)
  const inView = useInView(mainRef, { once: true, amount: 0.6 })

  return (
    <Flex
      alignItems="center"
      position="relative"
      width="100%"
      margin="7rem 0 5rem"
      ref={mainRef}
    >
      <MainImageArea>
        <StackedImageBox
          initial={{
            y: "-4rem",
            scale: 0.8,
          }}
          animate={{
            y: "-3rem",
            scale: 1,
            scaleX: 0.87,
            opacity: 1,
            background: "#0F1124",
          }}
          $borderOpacity="0.05"
          transition={getTransitionConfig(0.2)}
        />
        <StackedImageBox
          initial={{
            y: "-3rem",
            scale: 0.8,
          }}
          animate={{
            y: "-2rem",
            scale: 1,
            scaleX: 0.92,
            opacity: 1,
            background: "rgba(15, 17, 36, 0.25)",
          }}
          $borderOpacity="0.1"
          transition={getTransitionConfig(0.25)}
        />
        <StackedImageBox
          initial={{
            y: "-2rem",
            scale: 0.8,
          }}
          animate={{
            y: "-1rem",
            scale: 1,
            scaleX: 0.96,
            background: "rgba(15, 17, 36, 0.5)",
            opacity: 1,
          }}
          $borderOpacity="0.2"
          transition={getTransitionConfig(0.3)}
        />
        <StackedImageBox
          initial={{
            y: "-1rem",
            scale: 0.8,
          }}
          animate={{
            y: 0,
            scale: 1,
            opacity: 1,
            background: "var(--Windows-Glass, rgba(128, 128, 128, 0.2))",
          }}
          $borderOpacity="0.3"
          transition={getTransitionConfig(0.35)}
        />
        <StackedMainImage
          style={{ boxShadow: "rgb(0 0 0 / 60%) 0px 0px 64px 0px" }}
          initial={{
            opacity: 0,
            scale: 0.8,
          }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          transition={getTransitionConfig(0.4)}
          $loaded={heroLoaded}
        >
          <Image
            style={{ maxWidth: "1300px" }}
            lazy
            file={hero}
            onLoad={() => setHeroLoaded(true)}
          />
        </StackedMainImage>
        <StackedMainImageOverlay
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            delay: 1,
            duration: 0.4,
            ease: "backIn",
          }}
          $dark
          onAnimationComplete={() => setDetailsReady(true)}
        >
          <GlassyDiv noRadius>
            <Flex margin="0.625rem">
              <Image
                lazy
                file={images["243a7d7b-ba0a-484f-b415-86c5b4b93f70"]}
              />
            </Flex>
          </GlassyDiv>
        </StackedMainImageOverlay>
        <FadeOverlay />
      </MainImageArea>
      <StackedMainImageAI
        initial={{
          opacity: 0,
          y: "4rem",
        }}
        animate={
          detailsReady &&
          inView && {
            opacity: 1,
            y: 0,
          }
        }
        transition={{
          duration: 0.6,
          ease: "easeInOut",
        }}
      >
        <Image lazy file={images["57909c0b-c8f7-41a2-8d1c-7319b65261e8"]} />
      </StackedMainImageAI>
      <StackedMainImageDetails
        initial={{
          opacity: 0,
        }}
        animate={
          detailsReady &&
          inView && {
            opacity: 1,
          }
        }
        transition={{
          delay: 0.4,
          duration: 0.4,
          ease: "backIn",
        }}
      >
        <Image lazy file={images["b6dd436d-9cb3-45c4-8075-b641413c65a1"]} />
      </StackedMainImageDetails>
    </Flex>
  )
}
