import React, { useEffect } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  .custom-substack-widget + .success {
    margin: 0;
  }

  .custom-substack-widget {
    margin: 0 0 0.5rem;
  }
`

export const Substack = () => {
  useEffect(() => {
    const loadSubstackWidget = () => {
      if (window) {
        window.CustomSubstackWidget = {
          substackUrl: "metricstack.substack.com",
          placeholder: "example@klipfolio.com",
          buttonText: "Subscribe",
          theme: "custom",
          colors: {
            primary: "#00B277",
            input: "#FFFFFF",
            email: "#000000",
            text: "white",
          },
        }

        const scriptElement = document.createElement("script")
        scriptElement.src = "https://substackapi.com/widget.js"
        scriptElement.async = true

        const divElement = document.getElementById("custom-substack-embed")
        divElement.appendChild(scriptElement)
      }
    }

    loadSubstackWidget()
  }, [])

  return (
    <Wrapper>
      <div id="custom-substack-embed"></div>
    </Wrapper>
  )
}
