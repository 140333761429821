import React from "react"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Image from "@components/image"
import { graphql, useStaticQuery } from "gatsby"
import Div from "@components/elements/div"
import { HighGradient } from "@components/common/gradient"
import Heading from "@components/elements/heading"
import GradientText from "@components/common/gradient-text"
import { useGradient } from "@hooks/use-gradient"
import { useTheme } from "styled-components"

export const PowerMetricsPitch = () => {
  const { image } = useStaticQuery(graphql`
    query PowerMetricsPitch {
      image: directusFiles(
        directus_id: { eq: "72868c59-0863-4c01-af22-932a047cade0" }
      ) {
        directus_id
        cdn
        id
        placeholder
        title
      }
    }
  `)

  const { color } = useTheme()
  const { gradient } = useGradient()

  return (
    <Div
      position="relative"
      overflow="hidden"
      background={color.indigo700}
      padding="8rem 0"
      paddingSm="4rem 0"
      center
    >
      <HighGradient src={gradient.cdn} />
      <Div textContainer position="relative" zIndex={1}>
        <Heading as="h2" color="white" fontSize="3rem" margin="0 0 1rem">
          Provide the data,
          <br />
          <GradientText gradient="linear-gradient(90deg, #FFB85C 0%, #FDA1B9 40%, #707EFA 140%)">
            don’t build the dashboards.
          </GradientText>
        </Heading>
      </Div>
      <Div container margin="4rem auto 2rem">
        <Image file={image} />
      </Div>
      <Flex textContainer gap="1.5rem">
        <Paragraph color="white" lineHeight="150%">
          Dashboards for business users – Managed data for everyone.
          PowerMetrics puts data analysis and dashboard building into the hands
          of business teams, freeing up data teams to focus on what they do
          best.
        </Paragraph>
        <Paragraph color="white" lineHeight="150%">
          With its unique metric-centric architecture, PowerMetrics abstracts
          modelled data into metrics to hide complexity behind the scenes and
          enables approachable analytics for all.
        </Paragraph>
      </Flex>
    </Div>
  )
}
