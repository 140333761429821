import React from "react"

export const SubstackLogo = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="white" />
      <g clipPath="url(#clip0_218_10012)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6667 30H67.7029V34.9116H32.6667V30ZM32.6667 47.9156H67.7029V70L50.1812 60.1724L32.6667 70V47.9156ZM32.6667 38.9578H67.7029V43.8694H32.6667V38.9578Z"
          fill="#00B277"
        />
      </g>
      <defs>
        <clipPath id="clip0_218_10012">
          <rect
            width="35.0362"
            height="40"
            fill="white"
            transform="translate(32.6667 30)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
