import React, { useCallback, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"
import styled from "styled-components"
import Grid from "@components/elements/grid"
import media from "../../../styles/media"

const WrapContainer = styled.div`
  overflow: scroll;
  max-width: 100%;
  -ms-overflow-style: none;
  width: 100%;
  scrollbar-width: none;
  position: relative;

  ${media.sm`
    mask-image: linear-gradient(${({ right }) =>
      right
        ? "to left"
        : "to right"}, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 30%);
    mask-image: linear-gradient(${({ right }) =>
      right
        ? "to left"
        : "to right"}, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 30%);
  `}
`

const TabGrid = styled(Grid)`
  width: 100%;
`

export const TabContainer = ({ tabs, activeIndex, setActiveIndex }) => {
  const wrapRef = useRef(null)
  const tabGridRef = useRef(null)

  useEffect(() => {
    const tabGrid = tabGridRef.current
    const wrapper = wrapRef.current
    const containerWidth = tabGrid.offsetWidth

    let newScrollLeft

    if (activeIndex === 0) {
      newScrollLeft = 0
    } else if (activeIndex === tabs.length - 1) {
      newScrollLeft = containerWidth
    } else {
      newScrollLeft = containerWidth / tabs.length
    }

    wrapper.scrollTo({ left: newScrollLeft, top: 0, behavior: "smooth" })
  }, [activeIndex])

  return (
    <WrapContainer
      ref={wrapRef}
      right={activeIndex !== tabs.length - 1}
    >
      <TabGrid
        ref={tabGridRef}
        columns={`repeat(${tabs.length}, 1fr)`}
        columnsSm={`repeat(${tabs.length}, minmax(200px, 1fr))`}
        gap="0"
        margin="3rem 0 1.5rem"
      >
        {tabs.map((value, i) => (
          <Tab
            title={value}
            key={`tab-${i}`}
            index={i}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        ))}
      </TabGrid>
    </WrapContainer>
  )
}

TabContainer.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
}

const TabLayout = styled(Flex)`
  border-bottom: 4px solid
    ${({ isOpen, theme }) => (isOpen ? theme.color.indigo300 : "#D9D9D9")};
  ${media.sm`
    padding-right: 1rem;
    padding-left: 1rem;
  `}
`

export const TabPanel = ({ index, activeIndex, children }) => {
  const isOpen = activeIndex === index
  return isOpen && <Flex gap="1rem">{children}</Flex>
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export const Tab = ({ title, index, activeIndex, setActiveIndex }) => {
  const isOpen = activeIndex === index

  const handleToggle = useCallback(() => {
    if (!isOpen) {
      setActiveIndex(index)
    }
  }, [isOpen, index])

  return (
    <Div overflow="hidden">
      <TabLayout
        flexFlow="row"
        justifyContent="space-between"
        gap="1rem"
        onClick={handleToggle}
        alignItems="center"
        cursor="pointer"
        padding="0 0 1rem"
        transition="border-color 0.2s ease-in-out, background 0.2s ease-in-out, padding 0.2s ease-in-out"
        height="100%"
        isOpen={isOpen}
      >
        <Paragraph
          center
          margin="0 auto"
          lineHeight="140%"
          fontSize="1.1rem"
          fontWeight={700}
        >
          {title}
        </Paragraph>
      </TabLayout>
    </Div>
  )
}

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
}
